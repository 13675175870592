@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Font family and rendered font appear smoother */
* {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* Global Background */
html,
body {
	background-color: rgb(243 244 246);
	/* Light mode background */
}

@media (prefers-color-scheme: dark) {

	html,
	body {
		background-color: rgb(17 24 39);
		/* Dark mode background */
	}
}

/* Custom scrollbar */
.custom-scrollbar::-webkit-scrollbar {
	width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background-color: #f0f0f0;
	border-radius: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 5px;
	border: 2px solid #f0f0f0;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
	background-color: #888;
	width: 15px;
	border: 2px solid #888;
}

.custom-scrollbar::-webkit-scrollbar:hover {
	width: 15px;
}

/* Nav item animation on Hover */
.nav-item-animate-hover {
	background: linear-gradient(90deg, transparent 50%, white 50%);
	background-size: 200% 100%;
	background-position: left;
	transition: background 0.2s, color 0.2s;
	color: white;
}

.nav-item-animate-hover:hover {
	background-position: right;
	color: #003476;
}

/* Fade in animation for content */
.content-fade-in-enter,
.content-fade-in-appear {
	opacity: 0;
}

.content-fade-in-enter-active,
.content-fade-in-appear-active {
	opacity: 1;
	transition: opacity 400ms ease-in;
}

.content-fade-in-exit {
	opacity: 1;
}

.content-fade-in-exit-active {
	opacity: 0;
	transition: opacity 400ms ease-in;
}

.scan-region-highlight-svg {
	stroke: white !important;
}

.code-outline-highlight {
	stroke: green !important;
}

.text-overflow-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

button.reactour__close {
	width: 15px;
	top: 12px;
	right: 12px;
}

/* Animations for new credentials */
@keyframes highlight-filter {

	0%,
	100% {
		filter: brightness(1);
	}

	50% {
		filter: brightness(1.17);
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
		transform: translateY(-300px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.highlight-filter {
	animation: highlight-filter 2s ease-in-out;
}

.fade-in {
	animation: fade-in 1s ease-in-out;
}

/* Light and Dark mode input autofill */
input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 30px rgb(245, 245, 245) inset !important;
}

@layer components {
	.inputDarkModeOverride:-webkit-autofill {
		-webkit-box-shadow: 0 0 0 30px rgb(70, 70, 70) inset !important;
		-webkit-text-fill-color: white;
	}
}

/* Swiper style */
.swiper-slide-prev,
.swiper-slide-next {
	display: inline-flex !important;
}

/* React Modal style */
.ReactModal__Content:focus {
	outline: none;
}

.overflow-visible-force {
	overflow: visible !important;
}
